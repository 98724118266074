import { PortableText, PortableTextReactComponents } from '@portabletext/react';
import React, { ReactNode } from 'react';

const serializers: Partial<PortableTextReactComponents> = {
    types: {
        p: ({ value }: { value: ReactNode }) => <p>{value}</p>,
    },
    marks: {
        strong: ({ children }: { children: ReactNode }) => <strong>{children}</strong>,
        em: ({ children }: { children: ReactNode }) => <em>{children}</em>,
        link: ({ value, children }) => {
            const target = value.blank ? '_blank' : undefined;
            return (
                <a
                    href={value?.href}
                    target={target}
                    rel={target ? 'noopener noreferrer' : undefined}>
                    {children}
                </a>
            );
        },
    },
};

const PortableBlock: React.FC<any> = ({ value }) => {
    return <PortableText value={value} components={serializers} />;
};

export default PortableBlock;
