import React, { FC, useState } from 'react';

import { IFAQ } from '../../assets/interfaces/faqInterface/index';
import PortableBlock from '../UI/portableText/PortableText';

import styles from './FAQItem.module.scss';

type TFAQItem = {
    item: IFAQ;
    index: number;
    activeIndex: number;
    onClick: (index: number) => void;
};

const FAQItem: FC<TFAQItem> = ({ item, index, activeIndex, onClick }) => {
    const faqClass = index === activeIndex && styles['active'];

    return (
        <li
            className={`${styles.faq__item} ${faqClass}`}
            onClick={() => onClick(index)}
            style={{ animationDelay: `${0.5 + index * 0.05}s` }}>
            <div className={styles.faq__info}>
                <div className={styles.faq__point}>{item.faqQuestion}</div>
                {item.content && <PortableBlock value={item.content} className={styles.answer} />}
            </div>
            <button aria-label="Expand/contract" className="button">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none">
                    <path
                        d="M22.4 12.8L16 19.2L9.60002 12.8"
                        stroke="#0A163C"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
        </li>
    );
};

export default FAQItem;
