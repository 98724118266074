import Link from 'next/link';
import React, { FC, ReactNode } from 'react';

import styles from './NextLink.module.scss';

type TLink = {
    children: ReactNode;
    ariaLabel?: string;
    ariaLabelledby?: string;
    className?: string;
    href: string;
    target?: boolean;
};

const NextLink: FC<TLink> = ({ children, href, className, ariaLabel, target }) => {
    return (
        <Link
            href={href}
            className={`${styles.link} ${className}`}
            aria-label={ariaLabel}
            target={target ? '_blank' : undefined}
            rel={target ? 'noopener noreferrer' : undefined}>
            {children}
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none">
                <path
                    d="M6.43674 5.19924L12.7123 5.28763M12.7123 5.28763L12.8007 11.5632M12.7123 5.28763L5.28769 12.7123"
                    strokeWidth="1.7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </Link>
    );
};

export default NextLink;
