import React, { FC } from 'react';
import { useInView } from 'react-intersection-observer';

import createAnimationArr from '@/src/assets/helpers/createAnimationArr';
import { IFAQ } from '@/src/assets/interfaces/faqInterface';
import { ITheme } from '@/src/assets/interfaces/themeInterface';
import { advInfoShort } from '@/src/assets/objects/advInfo';

import Wrapper from '../../UI/wrapper/Wrapper';
import AdvantageList from '../../advantageList/AdvantageList';
import Layout from '../../layout/Layout';
import BundleSection from '../../sections/bundleSection/BundleSection';
import FAQSection from '../../sections/faqSection/FAQSection';
import Hero from '../../sections/hero/Hero';
import LatestSection from '../../sections/latestSection/LatestSection';
import SupportSection from '../../sections/supportSection/SupportSection';

import styles from './Home.module.scss';

type THome = {
    faq: IFAQ[];
    featured: Partial<ITheme>;
    themes: ITheme[];
};

const Home: FC<THome> = ({ faq, featured, themes }) => {
    const standardThemes =
        themes && featured && themes.filter((item) => featured.title !== item.title);

    const jsonLd = {
        '@context': 'https://schema.org/',
        '@type': 'WebPage',
        name: 'High Five Themes',
        description:
            'Harness the ethereal power of this platform to captivate your audience and unleash the full potential of your content.',
        url: `${process.env.NEXT_PUBLIC_BASE_URL}`,
    };
    const heading = 'Essential Theme Features for Enhanced Online Performance';

    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: `-180px`,
        threshold: 0,
    });

    return (
        <Layout
            meta={{
                metaTitle: 'Get Premium Ghost Themes & Templates | High Five Themes',
                metaDescription:
                    'Top marketplace for premium Ghost themes. Upgrade your blog with our user-friendly Ghost templates. Download the best Ghost blog themes for your blog now!',
            }}
            jsonLD={jsonLd}>
            <Hero />
            <LatestSection standartThemes={standardThemes} featured={featured} />
            <section className={styles.advantages__section} ref={ref}>
                <Wrapper>
                    <h2
                        className={`heading--2 ${styles.heading} ${
                            inView && styles.animate__heading
                        }`}>
                        {createAnimationArr(heading, styles.word__wrapper, 0, 0.01)}
                    </h2>
                    <AdvantageList advList={advInfoShort} />
                </Wrapper>
            </section>
            <BundleSection className={styles.bundle} showLink={true} />
            <FAQSection faqList={faq} />
            {featured && (
                <SupportSection
                    bgImage={featured.supportSectionImage!}
                    descr={featured.supportSectionDescription!}
                    title={featured.supportSectionTitle!}
                    docs={featured.documentationUrl!}
                />
            )}
        </Layout>
    );
};

export default Home;
