import classNames from 'classnames';
import Link from 'next/link';
import React, { FC } from 'react';
import { useInView } from 'react-intersection-observer';

import imageHelper from '@/src/assets/helpers/imageHelper';
import { ITheme } from '@/src/assets/interfaces/themeInterface';

import NextImage from '../UI/nextImage/NextImage';
import NextLink from '../UI/nextLink/NextLink';

import styles from './LatestCard.module.scss';

type TCard = {
    theme: Partial<ITheme>;
};

const LatestCard: FC<TCard> = ({ theme }) => {
    const { ref, inView } = useInView({
        initialInView: false,
        rootMargin: `-180px`,
        triggerOnce: true,
    });

    return (
        <>
            {theme && (
                <article
                    className={classNames(styles.card, { [styles[`card--animate`]]: inView })}
                    ref={ref}>
                    <Link href={`/themes/${theme.slug}`} className={styles.card__demos}>
                        <div className={styles.card__desktop}>
                            <NextImage
                                src={`${imageHelper(theme.themeDesktop).url}`}
                                alt={`${theme.title} Desktop`}
                                fill
                                sizes="(max-width: 820px) 84vw, 39vw"
                                style={{ objectFit: 'cover', objectPosition: 'top center' }}
                            />
                        </div>
                        <div
                            className={`${styles.card__mobile} ${
                                !theme.themeMobile && styles.backlog
                            }`}>
                            <NextImage
                                src={`${imageHelper(theme.themeMobile).url}`}
                                alt={`${theme.title} Mobile`}
                                fill
                                sizes="(max-width: 414px) 18.82vw, (max-width: 820px) 16.85vw, 7.82vw"
                                style={{ objectFit: 'cover', objectPosition: 'top center' }}
                            />
                        </div>
                    </Link>
                    <div className={styles.card__info}>
                        <div>
                            <Link
                                href={`/themes/${theme.slug}`}
                                className={`heading--5 ${styles.card__name}`}>
                                {theme.title}
                            </Link>
                            <p className={styles.card__excerpt}>{theme.excerpt}</p>
                        </div>
                        <NextLink
                            href={`/themes/${theme.slug}`}
                            ariaLabel={`Explore ${theme.title}`}
                            className={styles.card__link}>
                            Explore {theme.title}
                        </NextLink>
                    </div>
                </article>
            )}
        </>
    );
};

export default LatestCard;
