import { client } from '@/sanity/lib/client';
import { GetStaticPropsContext } from 'next';

import { IFAQ } from '../assets/interfaces/faqInterface';
import { ITheme } from '../assets/interfaces/themeInterface';
import { faqQuery } from '../assets/queries/faqQuery';
import featuredThemeQuery from '../assets/queries/featuredThemeQuery';
import themeArchiveQuery from '../assets/queries/themeArchiveQuery';
import Home from '../components/screens/Home/Home';

type THomePageProps = {
    data: IFAQ[];
    featured: {
        featuredTheme: Partial<ITheme>;
    };
    themes: ITheme[];
};

export default function HomePage({ data, featured, themes }: THomePageProps) {
    return <Home faq={data} featured={featured.featuredTheme} themes={themes} />;
}

export async function getStaticProps(context: GetStaticPropsContext) {
    const data = await client.fetch(faqQuery);
    const featuredTheme = await client.fetch(featuredThemeQuery);
    const themes = await client.fetch(themeArchiveQuery);
    return {
        props: {
            data,
            featured: featuredTheme,
            themes,
        },
        revalidate: 10,
    };
}
