import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/free-mode';
import { Autoplay, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import slide1 from '@/public/images/gallery/slide_1.jpg';
import slide2 from '@/public/images/gallery/slide_2.jpg';
import slide3 from '@/public/images/gallery/slide_3.jpg';
import slide4 from '@/public/images/gallery/slide_4.jpg';
import slide5 from '@/public/images/gallery/slide_5.jpg';

import NextImage from '../UI/nextImage/NextImage';

import styles from './Gallery.module.scss';

const Gallery = () => {
    const desktop =
        '(max-width: 414px) 68.04vw, (max-width: 576px) 56.7vw, (max-width: 768px) 47.25vw, 37.8125vw';
    const mobile =
        '(max-width: 414px) 21.096vw, (max-width: 576px) 17.58vw, (max-width: 768px) 14.65vw, 11.719vw';

    const initialSpeed = 15000;

    return (
        <Swiper
            modules={[Autoplay, FreeMode]}
            slidesPerView="auto"
            initialSlide={0}
            allowTouchMove={false}
            shortSwipes={false}
            longSwipes={false}
            className={`${styles.swiper} gallery-autoplay-swiper`}
            loop={true}
            autoplay={{
                disableOnInteraction: false,
                pauseOnMouseEnter: false,
                delay: 0,
            }}
            speed={initialSpeed}
            onSlideChange={(swiper) => {
                const active = swiper.realIndex;
                swiper.params.speed =
                    active === 2 || active === 6 ? initialSpeed / 3.225 : initialSpeed;
            }}>
            <SwiperSlide key="slide1" className={`${styles.slide} ${styles.desktop}`}>
                <div className={styles.slide__content}>
                    <NextImage
                        sizes={desktop}
                        src={slide1.src}
                        alt="Slide1"
                        fill
                        priority
                        style={{ objectFit: 'cover', objectPosition: 'center center' }}
                    />
                </div>
            </SwiperSlide>
            <SwiperSlide key="slide2" className={`${styles.slide} ${styles.desktop}`}>
                <div className={styles.slide__content}>
                    <NextImage
                        sizes={desktop}
                        src={slide2.src}
                        alt="Slide2"
                        fill
                        priority
                        style={{ objectFit: 'cover', objectPosition: 'center center' }}
                    />
                </div>
            </SwiperSlide>
            <SwiperSlide key="slide3" className={`${styles.slide} ${styles.mobile}`}>
                <div className={styles.slide__content}>
                    <NextImage
                        sizes={mobile}
                        src={slide3.src}
                        alt="Slide3"
                        fill
                        priority
                        style={{ objectFit: 'cover', objectPosition: 'center center' }}
                    />
                </div>
            </SwiperSlide>
            <SwiperSlide key="slide4" className={`${styles.slide} ${styles.desktop}`}>
                <div className={styles.slide__content}>
                    <NextImage
                        sizes={desktop}
                        src={slide4.src}
                        alt="Slide4"
                        fill
                        priority
                        style={{ objectFit: 'cover', objectPosition: 'center center' }}
                    />
                </div>
            </SwiperSlide>
            <SwiperSlide key="slide5" className={`${styles.slide} ${styles.desktop}`}>
                <div className={styles.slide__content}>
                    <NextImage
                        sizes={desktop}
                        src={slide5.src}
                        alt="Slide5"
                        fill
                        priority
                        style={{ objectFit: 'cover', objectPosition: 'center center' }}
                    />
                </div>
            </SwiperSlide>
            <SwiperSlide key="slide6" className={`${styles.slide} ${styles.desktop}`}>
                <div className={styles.slide__content}>
                    <NextImage
                        sizes={desktop}
                        src={slide2.src}
                        alt="Slide6"
                        fill
                        priority
                        style={{ objectFit: 'cover', objectPosition: 'center center' }}
                    />
                </div>
            </SwiperSlide>
            <SwiperSlide key="slide7" className={`${styles.slide} ${styles.mobile}`}>
                <div className={styles.slide__content}>
                    <NextImage
                        sizes={mobile}
                        src={slide3.src}
                        alt="Slide7"
                        fill
                        priority
                        style={{ objectFit: 'cover', objectPosition: 'center center' }}
                    />
                </div>
            </SwiperSlide>
            <SwiperSlide key="slide8" className={`${styles.slide} ${styles.desktop}`}>
                <div className={styles.slide__content}>
                    <NextImage
                        sizes={desktop}
                        src={slide4.src}
                        alt="Slide8"
                        fill
                        priority
                        style={{ objectFit: 'cover', objectPosition: 'center center' }}
                    />
                </div>
            </SwiperSlide>
        </Swiper>
    );
};

export default Gallery;
