import classNames from 'classnames';
import React, { FC } from 'react';
import { useInView } from 'react-intersection-observer';

import imageHelper from '@/src/assets/helpers/imageHelper';
import IImage from '@/src/assets/interfaces/imageiInterface';

import Button from '../../UI/button/Button';
import NextImage from '../../UI/nextImage/NextImage';
import NextLabel from '../../UI/nextLabel/NextLabel';
import Wrapper from '../../UI/wrapper/Wrapper';

import styles from './SupportSection.module.scss';

type TSupport = {
    title: string;
    descr: string;
    bgImage: IImage;
    docs: string;
};
const SupportSection: FC<TSupport> = ({ bgImage, descr, title, docs }) => {
    const { ref, inView } = useInView({
        initialInView: false,
        rootMargin: `-180px`,
        triggerOnce: true,
    });

    return (
        <section
            className={classNames(styles.support__section, {
                [styles[`support__section--animate`]]: inView,
            })}
            ref={ref}>
            <Wrapper>
                <div className={styles.section__container}>
                    <div className={styles.section__left}>
                        <NextLabel>Support</NextLabel>
                        <div className={styles.section__info}>
                            {title && <h3 className={`heading--3 ${styles.heading}`}>{title}</h3>}
                            {descr && <p className={`excerpt ${styles.excerpt}`}>{descr}</p>}
                            <Button
                                ariaLabel="View Documentation"
                                color="standard"
                                buttonUrl={docs}
                                target>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="19"
                                    viewBox="0 0 18 19"
                                    fill="none">
                                    <path
                                        d="M15.023 6.28953L11.0855 2.35203C11.0332 2.29981 10.9711 2.2584 10.9029 2.23017C10.8346 2.20194 10.7614 2.18744 10.6875 2.1875H3.9375C3.63913 2.1875 3.35298 2.30603 3.142 2.517C2.93103 2.72798 2.8125 3.01413 2.8125 3.3125V15.6875C2.8125 15.9859 2.93103 16.272 3.142 16.483C3.35298 16.694 3.63913 16.8125 3.9375 16.8125H14.0625C14.3609 16.8125 14.647 16.694 14.858 16.483C15.069 16.272 15.1875 15.9859 15.1875 15.6875V6.6875C15.1876 6.61361 15.1731 6.54043 15.1448 6.47215C15.1166 6.40386 15.0752 6.34181 15.023 6.28953ZM10.6875 6.6875V3.59375L13.7812 6.6875H10.6875Z"
                                        fill="#0A163C"
                                    />
                                </svg>
                                <span>View Documentation</span>
                            </Button>
                        </div>
                    </div>

                    {bgImage && (
                        <div className={styles.support__img}>
                            <NextImage
                                src={`${imageHelper(bgImage).url}`}
                                alt="Support Image"
                                fill
                                loading="lazy"
                                sizes="
                                (max-width: 376px) 420px,
                                (max-width: 414px) 480px,
                                (max-width: 576px) 576px,
                                (max-width: 640px) 96vw,
                                (max-width: 820px) 92.83vw, 
                                (max-width: 1024px) 60.94vw, 
                                (max-width: 1200px) 56vw, 
                                (max-width: 1480px) 51.43vw, 
                                                    53.95vw"
                            />
                        </div>
                    )}
                </div>
            </Wrapper>
        </section>
    );
};

export default SupportSection;
