import React, { FC, ReactNode } from 'react';

import styles from './NextLabel.module.scss';

type TLabel = {
    children: ReactNode;
};

const NextLabel: FC<TLabel> = ({ children }) => {
    return <label className={styles.label}>{children}</label>;
};

export default NextLabel;
