import React from 'react';
import { useInView } from 'react-intersection-observer';

import createAnimationArr from '@/src/assets/helpers/createAnimationArr';

import Button from '../../UI/button/Button';
import Wrapper from '../../UI/wrapper/Wrapper';
import Gallery from '../../gallery/Gallery';

import styles from './Hero.module.scss';

const Hero = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0,
    });

    const excerpt = `Exceptional Ghost themes for every publisher, from leading media sites to personal blogs, 
    craft your vision seamlessly.`;

    return (
        <section className={styles.hero__section}>
            <Wrapper className={styles.hero__wrapper}>
                <div ref={ref}>
                    <h1
                        className={`heading--1 ${styles.heading} ${
                            inView && styles.animate__heading
                        }`}>
                        <span className={styles.word__wrapper}>
                            <span style={{ animationDelay: `0.1s` }}>Premium&nbsp;</span>
                        </span>
                        <span className={styles.word__wrapper}>
                            <span style={{ animationDelay: `0.11s` }}>Ghost&nbsp;</span>
                        </span>
                        <span className={styles.word__wrapper}>
                            <span style={{ animationDelay: `0.12s` }}>Themes&nbsp;</span>
                        </span>
                        <span className={styles.word__wrapper}>
                            <span style={{ animationDelay: `0.13s` }}>to&nbsp;</span>
                        </span>
                        <span className={styles.word__wrapper}>
                            <span style={{ animationDelay: `0.14s` }}>Captivate&nbsp;</span>
                        </span>
                        <span className={styles.word__wrapper}>
                            <span style={{ animationDelay: `0.15s` }}>Any&nbsp;</span>
                        </span>
                        <span className={styles.word__wrapper}>
                            <span style={{ animationDelay: `0.16s` }}>Audience</span>
                        </span>
                    </h1>
                    <p className={`excerpt ${styles.excerpt} ${inView && styles.animate__excerpt}`}>
                        {createAnimationArr(excerpt, styles.word__wrapper, 0.3, 0.01)}
                    </p>
                    <div className={styles.buttons}>
                        <Button buttonUrl="/themes" ariaLabel="Discover Themes" color="blue">
                            Discover Themes
                        </Button>
                        <Button
                            ariaLabel="Learn more"
                            color="standard"
                            buttonUrl="/themes/reporter"
                            target>
                            Learn more
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none">
                                <path
                                    d="M6.43674 5.19931L12.7123 5.28769M12.7123 5.28769L12.8007 11.5633M12.7123 5.28769L5.28769 12.7123"
                                    stroke="#0A163C"
                                    strokeWidth="1.7"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </Button>
                    </div>
                </div>
            </Wrapper>
            <Gallery />
        </section>
    );
};

export default Hero;
