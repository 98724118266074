import classNames from 'classnames';
import Link from 'next/link';
import React, { FC } from 'react';
import { useInView } from 'react-intersection-observer';

import imageHelper from '@/src/assets/helpers/imageHelper';
import { ITheme } from '@/src/assets/interfaces/themeInterface';

import Button from '../../UI/button/Button';
import NextImage from '../../UI/nextImage/NextImage';
import NextLabel from '../../UI/nextLabel/NextLabel';
import Wrapper from '../../UI/wrapper/Wrapper';
import LatestCard from '../../latestCard/LatestCard';

import styles from './LatestSection.module.scss';

type TLatest = {
    featured: Partial<ITheme>;
    standartThemes: ITheme[];
};
const LatestSection: FC<TLatest> = ({ featured, standartThemes }) => {
    const { ref, inView } = useInView({
        initialInView: false,
        rootMargin: `-180px`,
        triggerOnce: true,
    });

    return (
        <section className={styles.latest__section}>
            <Wrapper>
                {featured && (
                    <article
                        className={classNames(styles.theme, { [styles[`theme--animate`]]: inView })}
                        ref={ref}>
                        <div className={styles.section__left}>
                            <NextLabel>Featured Theme</NextLabel>
                            <div className={styles.section__info}>
                                <Link
                                    href={`/themes/${featured.slug}`}
                                    className={`heading--3 ${styles.heading}`}>
                                    {featured.title}
                                </Link>
                                <p className={`excerpt ${styles.excerpt}`}>{featured.excerpt}</p>
                                <div className={styles.buttons}>
                                    <Button
                                        buttonUrl={`themes/${featured.slug}`}
                                        ariaLabel={`Explore ${featured.title}`}
                                        color="blue">
                                        Explore {featured.title}
                                    </Button>
                                    <Button
                                        ariaLabel="Explore All Themes"
                                        color="standard"
                                        className={styles.button}
                                        buttonUrl="/themes">
                                        Explore All Themes
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none">
                                            <path
                                                d="M6.43674 5.19931L12.7123 5.28769M12.7123 5.28769L12.8007 11.5633M12.7123 5.28769L5.28769 12.7123"
                                                stroke="#0A163C"
                                                strokeWidth="1.7"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <Link href={`/themes/${featured.slug}`} className={styles.theme__right}>
                            <div className={styles.theme__mobile}>
                                <NextImage
                                    src={`${imageHelper(featured.themeMobile).url}`}
                                    alt="Spotlight mobile"
                                    fill
                                    sizes="
                                        (max-width: 376px) 26vw,
                                        (max-width: 480px) 24vw,
                                        (max-width: 576px) 23.33vw,
                                        (max-width: 640px) 20vw,
                                        (max-width: 820px) 16.22vw,
                                        (max-width: 1024px) 9.7vw, 
                                                            10.625vw"
                                />
                            </div>
                            <div className={styles.theme__desktop}>
                                <NextImage
                                    src={`${imageHelper(featured.themeDesktop).url}`}
                                    alt="Spotlight desktop"
                                    fill
                                    sizes="
                                            (max-width: 376px) 70.85vw,
                                            (max-width: 480px) 68vw,
                                            (max-width: 576px) 66.67vw,
                                            (max-width: 640px) 55.94vw,
                                            (max-width: 820px) 54.85vw,
                                            (max-width: 1024px) 35.87vw, 
                                                                41.67vw"
                                />
                            </div>
                        </Link>
                    </article>
                )}
                <ul className={styles.theme__cards}>
                    {standartThemes &&
                        standartThemes.map((theme, i) => {
                            return (
                                <li key={`${theme._id}+${i}`}>
                                    <LatestCard theme={theme} />
                                </li>
                            );
                        })}
                </ul>
            </Wrapper>
        </section>
    );
};

export default LatestSection;
