import React, { FC } from 'react';
import { useInView } from 'react-intersection-observer';

import createAnimationArr from '@/src/assets/helpers/createAnimationArr';

import { IFAQ } from '../../../assets/interfaces/faqInterface/index';
import Wrapper from '../../UI/wrapper/Wrapper';
import FAQList from '../../faqList/FAQList';

import styles from './FAQSection.module.scss';

type TFAQ = {
    faqList: IFAQ[];
};

const FAQSection: FC<TFAQ> = ({ faqList }) => {
    const heading = 'Frequently Asked Questions';
    const excerpt =
        'Find quick, expert answers to common queries about our Ghost themes and templates in our FAQ section.';

    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: `-300px 0px`,
        threshold: 0,
    });

    return (
        <section className={styles.faq}>
            <Wrapper className={styles.wrapper}>
                <div className={styles.faq__info} ref={ref}>
                    <h2
                        className={`heading--2 ${styles.heading} ${
                            inView && styles.animate__heading
                        }`}>
                        {createAnimationArr(heading, styles.word__wrapper, 0, 0.01)}
                    </h2>
                    <p className={`excerpt ${styles.excerpt} ${inView && styles.animate__excerpt}`}>
                        {createAnimationArr(excerpt, styles.word__wrapper, 0.1, 0.01)}
                    </p>
                </div>
                <FAQList faqList={faqList} />
            </Wrapper>
        </section>
    );
};

export default FAQSection;
