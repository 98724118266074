import React, { FC, useState } from 'react';

import { IFAQ } from '../../assets/interfaces/faqInterface/index';
import FAQItem from '../faqItem/FAQItem';

import styles from './FAQList.module.scss';

type TFAQList = {
    faqList: IFAQ[];
};

const FAQList: FC<TFAQList> = ({ faqList }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const onClick = (index: number) => {
        if (activeIndex === index) {
            setActiveIndex(-1);
            return;
        }

        setActiveIndex(index);
    };

    return (
        <div className={styles.faq__wrapper}>
            <ul className={styles.faq__list}>
                {faqList.map((item, i) => {
                    return (
                        <FAQItem
                            key={`${item._id}__${i}`}
                            item={item}
                            index={i}
                            activeIndex={activeIndex}
                            onClick={onClick}
                        />
                    );
                })}
            </ul>
        </div>
    );
};

export default FAQList;
